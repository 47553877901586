<template>
  <section class="l-content">
    <div class="flex">
      <div class="flex-sub margin-xs">
        <el-card v-if="pageData">
          <div slot="header" class="clearfix">
            <span>盘点计划</span>
          </div>
          <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
            <template slot="assetName" slot-scope="scope">
              <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
            </template>
            <template slot="realCount" slot-scope="scope">
              <span>{{ scope.row.realCount }} / {{ scope.row.planCount }}</span>
              <el-button type="warning" class="fr" @click="setPlanDetail(scope.row)" icon="el-icon-plus" circle size="mini"></el-button>
            </template>
          </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </el-card>
      </div>
      <div class="flex-twice margin-xs">
        <el-card v-if="selPlan">
          <div slot="header" class="clearfix">
            <div>
              计划明细
              <span v-if="selPlan">【{{ selPlan.planName }}】【{{ selPlan.planCount }}】</span>
              <!-- <p class="fr" style="margin-top:-5px;width:120px;">
                <l-select v-model="condition.categoryCode" :remote="$api.assetCategoryList"></l-select>
              </p> -->
            </div>
          </div>
          <l-table :option="table2Option" :data="selPlan.detailList">
            <template slot="assetName" slot-scope="scope">
              <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
            </template>
          </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </el-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import planAddoredit from './plan_addoredit'
import planDetailEdit from './plan_detail_edit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AssetList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '计划名称', prop: 'planName' }
        ],
        operation: {
          width: '180',
          header: { type: 'warning', icon: 'el-icon-plus', label: '添加', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '', click: this.del },
            { type: '', icon: 'el-icon-right', label: '', click: this.sel }
          ]
        }
      },
      table2Option: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产分类', prop: 'categoryFullName' }
        ],
        operation: {
          width: '160',
          header: { type: 'warning', icon: 'el-icon-plus', label: '添加', click: this.setPlanDetail },
          buttons: [{ type: 'danger', icon: 'el-icon-delete', label: '', click: this.planDetailDel }]
        }
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        planState: '',
        pageIndex: 1,
        pageSize: 10
      },
      selPlan: null,
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/assetplan/pagedlist', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: planAddoredit,
        title: '新增资产盘点计划',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode
          }
        }
      })
      this.$lgh.toastSuccess('新建资产盘点计划成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: planAddoredit,
        title: '修改资产盘点计划',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改资产盘点计划成功')
      this.getPageData()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除?', p => {
        this.$lgh
          .get('api/enterprise/assetplan/delete', {
            enterpriseCode: item.enterpriseCode,
            planId: item.planId
          })
          .then(r => {
            this.getPageData()
          })
      })
    },
    sel(item) {
      this.selPlan = Object.assign({ detailList: null }, item)
      this.$lgh.get('api/enterprise/assetplan/detail/list', { planId: item.planId }).then(res => {
        this.selPlan.detailList = res
      })
    },
    async setPlanDetail() {
      await this.$lgh.openDialog({
        ins: planDetailEdit,
        title: '修改资产盘点明细',
        data: {
          dataInfo: this.selPlan
        }
      })
      this.$lgh.toastSuccess('修改明细成功')
      this.sel(this.selPlan)
    },
    planDetailDel(item) {
      this.$lgh.get('api/enterprise/assetplan/detail/delete', { detailId: item.detailId, planId: item.planId }).then(res => {
        this.sel(this.selPlan)
      })
    },
    setUseUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode).then(user => {
        this.$lgh
          .get('api/enterprise/asset/set/useuser', {
            assetId: item.assetId,
            userId: user.userId,
            recordUserId: this.userInfo.userId,
            recordUserName: this.userInfo.fullName
          })
          .then(e => {
            this.getPageData()
          })
      })
    }
  }
}
</script>
