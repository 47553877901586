<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <div class="padding-mini">
      <el-card class="padding5">
        <el-form class="margin0" :inline="true" :model="condition">
          <el-form-item label="资产分类">
            <l-select v-model="condition.categoryCode" :remote="$api.assetCategoryList" @change="getPageData"></l-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getPageData()">查询</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-divider> </el-divider>
      <el-card v-if="pageData">
        <el-table ref="multipleTable" :data="pageData.list" tooltip-effect="dark" style="width: 100%" :height="height" border @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="资产名称">
            <template slot-scope="scope">
              <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="assetCode" label="资产编号"> </el-table-column>
          <el-table-column prop="categoryFullName" label="资产分类"> </el-table-column>
          <el-table-column prop="reponsibleUserName" label="责任人" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="useUserName" label="使用人" show-overflow-tooltip> </el-table-column>
        </el-table>
        <l-pager :page="pageData" @change="getPageData"></l-pager>
      </el-card>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">添加到计划中</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      pageData: null,
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        categoryCode: '',
        pageIndex: 1,
        pageSize: 1000
      },
      multipleSelection: [],
      height: document.documentElement.clientHeight * 0.9 - 300
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/asset/list', ['enterpriseCode', this.condition])
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async save() {
      if (this.multipleSelection.length === 0) return

      var ids = this.multipleSelection.map(a => a.assetId)
      await this.$lgh.get('api/enterprise/assetplan/detail/add/mutil', {
        planId: this.dataInfo.planId,
        assetIdList: ids.join(',')
      })
      this.visible = false
      this.onsuccess && this.onsuccess(this.dataInfo)
    }
  }
}
</script>

<style></style>
